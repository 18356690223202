import React from 'react';
import { Link } from 'gatsby';
import facebookIcon from '../../images/icon-facebook.svg';
import instagramIcon from '../../images/icon-ig.svg';

const Footer = () => {
    return(
        <footer className="footer">
            <div className="footer__nav">
                <ul className="footer__links">
                    <li>
                        <Link to="/products/">
                            Our Wine
                        </Link>
                    </li>
                    <li>
                        <Link to="/our-process/">
                            Our Process
                        </Link>
                    </li>
                    <li>
                        <Link to="/about-us/">
                            About Us
                        </Link>
                    </li>
                    <li>
                        <Link to="/blog/">
                            Blog
                        </Link>
                    </li>
                    <li>
                        <Link to="/contact-us/">
                            Contact
                        </Link>
                    </li>
                </ul>
            </div> 
            <ul className="footer__social social">
                <li>
                    <a href="https://www.facebook.com/FreedomWineCompany/" className="social__icon" target="_blank" rel="noopener noreferrer">
                        <img src={facebookIcon} alt="facebook icon" />
                    </a>
                </li>
                <li>
                    <a href="https://www.instagram.com/freedomwine/" className="social__icon"  target="_blank" rel="noopener noreferrer">
                        <img src={instagramIcon} alt="facebook icon" />
                    </a>
                </li>
            </ul>
            <ul className="footer__legal">
                <li>
                    &copy; {(new Date().getFullYear())} Freedom Wine Company
                </li>
                <li>
                    All Rights Reserved
                </li>
            </ul>
        </footer>
    )
}

export default Footer;
