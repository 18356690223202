import React from "react";
import { Link } from "gatsby";
import logo from '../../images/freedom-wine-co-black.svg';
import { motion, AnimatePresence } from 'framer-motion'
import { useMediaQuery } from 'react-responsive'


const Header = ({toggleMenu, setToggleMenu}) => {

	const isSmallScreen = useMediaQuery({ query: '(max-width: 750px)' })
	const isLargeScreen = useMediaQuery({ query: '(min-width: 751px)' })
  
	return (
		<header className="header">
		  <div className="header__nav">
  
			<button
			  className="header__burger"
			  onClick={() => setToggleMenu(!toggleMenu)}
			>
			  <span></span>
			</button>
  
			{isSmallScreen && 
			  <AnimatePresence>
				  {toggleMenu && (
					<motion.ul className="header__menu"
					  initial = {{x: "-100%"}}
					  exit =  {{x: "-100%"}}
					  animate = {{x: toggleMenu ? 0 : "-100%"}}
					  transition={{ duration: 0.5, linear: [0.6, 0.05, -0.01, 0.9] }}
					>
					  <li>
						<Link to="/products/">
						  Our Wine
						</Link>  
					  </li>   
					  <li>
						<Link to="/our-process/">
						  Our Process
						</Link>  
					  </li>   
					  {/* <li>
						<Link to="/wine-club/">
						  Wine Club
						</Link>    
					  </li>    */}
					  <li>
						<Link to="/about-us/">
						  About
						</Link>  
					  </li> 
					  <li>
						<Link to="/blog/">
						  Blog
						</Link>  
					  </li>    
					</motion.ul>     
				  )}
			  </AnimatePresence>
			}
			{isLargeScreen &&
			  <ul className="header__menu">
				<li>
				  <Link to="/products/">
					Our Wine
				  </Link>  
				</li>   
				<li>
				  <Link to="/our-process/">
					Our Process
				  </Link>  
				</li>   
				{/* <li>
				  <Link to="/wine-club/">
					Wine Club
				  </Link>  
				</li>    */}
				<li>
				  <Link to="/about-us/">
					About
				  </Link>  
				</li> 
				<li>
				  <Link to="/blog/">
					Blog
				  </Link>  
				</li>    
			  </ul>   
			}
  
  
		  </div>
  
		  <Link to="/" className="header__logo">
			<img src={ logo } alt="Freedom Wine Logo" />
		  </Link>
  
		  <div className="header__utility">
			<Link to="/contact-us/">
			  Contact
			</Link>
			{/* <Navigation /> */}
		  </div>
		</header>
	)
  
  }
  
  export default Header;