/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from 'react'
import PropTypes from "prop-types"
import { StaticQuery, graphql } from 'gatsby'
// import { motion } from 'framer-motion'
import Header from "../Header/Header"
import Footer from "../Footer/Footer"
import { useMediaQuery } from 'react-responsive'

const Layout = ({ children }) => {

  const [toggleMenu, setToggleMenu] = useState(false)
  const isSmallScreen = useMediaQuery({ query: '(max-width: 750px)' })
  const isLargeScreen = useMediaQuery({ query: '(min-width: 751px)' })

  return (
    <>
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <>
              {isSmallScreen && 
                  <Header 
                      toggleMenu = { toggleMenu }
                      setToggleMenu = { setToggleMenu }
                  />
              }
              {isLargeScreen &&
                <Header />
              }
                {children}
              <Footer />
          </>
        )}
      />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
